/* Style1.css */

* {
  padding: 0;
  margin: 0;
}

section {
  padding-top: 100px;
  padding-bottom: 100px;
}

.align-center {
  text-align: center;
}

/* Default styles for the navbar */
.navbar,
.navbar-nav .nav-item .nav-link {
  background: transparent;
  /* Initially transparent background */
  transition: background 0.3s;
  /* Smooth transition for background color */
  color: white;
}


/* Styles for the navbar when scrolled 
.navbar.scrolled{
  background: white; 
  box-shadow: 0px 25px 42px rgba(0, 0, 0, 0.2); 
}*/

/* Text color for the navbar when scrolled 
.navbar.scrolled .navbar-nav .nav-item .nav-link {
  color: black;
}*/

/* Text color for the form button when the navbar is not scrolled */
.navbar:not(.scrolled) form button {
  color: white;
  /* Text color when not scrolled */
}



/* Text color for the form button when the navbar is scrolled 
.navbar.scrolled form button {
  color: white; 
}*/



a {
  text-decoration: none;
}

.navbar-nav .nav-item .nav-link {

  text-decoration: none;
  position: relative;
  margin-left: 20px;
  letter-spacing: 2px;
  font-family: 'Signika Negative', sans-serif;

}

button.navbar-toggler {
  border: 0;
}

button.navbar-toggler:focus {
  box-shadow: none;
}


.secondnav.navbar-nav .nav-link::after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  content: "";
  background-color: #cf2d50;
  width: 0%;
  color: white;
  height: 4px;
  transition: all 0.5s;
}

.secondnav.navbar-nav .nav-link.active::after,
.secondnav.navbar-nav .nav-link:hover::after {
  width: 100%;
}

.btn-whatsapp.navbar-nav .nav-link.active::after,
.btn-whatsapp.navbar-nav .nav-link:hover::after {
  display: none;
}


form.d-flex {
  margin-left: 35px;
}


.navbar-nav li.language {
  float: inline-end;
}

.carousel-item {
  position: relative;
}

.carousel-item img {
  height: 700px;
}

div.imageoverlay::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;
  opacity: 0.6;
}

#demo div.carousel-indicators button.active {
  background-color: #cf2d50;
  margin: 6px;
  height: 16px;
  width: 16px;
}

#demo div.carousel-indicators button {
  background-color: #fff;
  margin: 6px;
  height: 16px;
  width: 16px;
}

div.carouseltext {
  position: absolute;
  top: 45%;

  width: 100%;

}

.carouseltext h1 {


  font-family: 'Poppins', sans-serif;
}

.carouseltext h4 {
  font-family: 'Jost', sans-serif;

}

div img.image{
  width: 33%;
  height: 580px;
  padding-top: 150px;
}


.button {
  background: #cf2d50;
  border: 3px solid #cf2d50;
  border-radius: 15px;
  color: white;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 2px;
  cursor: pointer;
  padding: 15px 30px;
  position: relative;
  transition: 0.5s ease-in-out;
}

.button::before {
  content: '';
  position: absolute;
  inset: 0;
  color: white;
  background: #cf2d50;
  clip-path: circle(0% at 50% 50%);
  z-index: -1;
  /* Change z-index to -1 to make sure it's behind the button text */
  transition: 0.5s ease-in-out;
}

button a {
  color: white;
}

button:hover a {
  color: #cf2d50 !important;
}

.button:hover {

  background: white;
}

.button:hover::before {
  clip-path: circle(100% at 50% 50%);
}

h1 {
  font-family: 'Signika Negative', sans-serif;
}

h1 span {
  color: #cf2d50;
}

/* about section starts in carousel page */
.about p {
  font-family: 'Public Sans', sans-serif;
  color: #333231;
  font-size: 17px;
  letter-spacing: 1px;
}

div.para {
  margin-left: -100px;
  padding-right: 100px;
  font-size: 17px;
  padding: 25px;
  word-spacing: normal;
}

.tamil-text {
  word-spacing: -0.1em;
  /* Adjust word spacing for Tamil text */
}

img.lord {
  width: 400px;
}

/* gallery section starts in carousel page */
section.gallery img {
  max-width: 100%;
  /* Make images responsive */

}

section.gallery img.pic1 {
  height: 300px;
}

section.gallery img.pic2 {
  height: 450px;
}

section.gallery img.pic3 {
  height: 420px;
}

section.gallery img.pic4 {
  height: 320px;
}


section.gallery {
  background-color: #ebe8e4;
}

section.gallery div.pics {
  padding-top: 100px;
}

section.gallery div.col-12 img {
  border-radius: 3px;
}

section.gallery div.col-12 img:hover {

  box-shadow: 0px 25px 42px rgba(0, 0, 0, 0.2);
}

img.images {
  margin-left: 50px;
  width: 350px;
}

img.images1 {
  margin-top: -90px;
}

img.images2 {
  width: 380px;
}

img.images3 {
  margin-left: -20px;
  width: 350px;
}

section.gallery img:hover {
  filter: hue-rotate(90deg);
}

/* footer part starts */
div.footer h4 {
  color: white;
}

div.footer p a {
  text-decoration: none;
}

div.footer div.section {
  margin-bottom: 150px;
}

div.footer div.touch {
  margin-bottom: 50px;
}

div.footer p a i,
p span {
  color: #cf2d50;
}

.footer p,
p a {
  color: #bbbbbb;
}

div.footer p a.mail:hover,
a.links:hover {
  color: #bbbbbb;
}





/*About page starts here */

h6.link a {
  color: white;
}

.container-fluid {
  position: relative;
}

.carouseltext {
  position: absolute;
  top: 50%;
  color: white;
  width: 100%;
  text-align: center;
}

.topleft {
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  color: white;
  font-size: 18px;
}


.overlay::before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #000;
  opacity: 0.7;
}

/* below code is to add style to languageswitcher  */
button.switcher {
  margin-left: 25px;
}

/* below code is used to add animated border effect on image in about page */
* {
  box-sizing: border-box;
}

.box {
  width: 350px;
  overflow: hidden;
  position: relative;
}



.box:before,
.box:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  opacity: 0;
  z-index: 1;
  transition: all 0.5s;
  border: 2px solid transparent;
  /* Add this line */
}

.box:before {
  bottom: 5%;
  left: 5%;
  border-bottom-color: #fff;
  /* Change 'border-bottom' to 'border-bottom-color' */
  border-left-color: #fff;
  /* Change 'border-left' to 'border-left-color' */
}

.box:after {
  top: 5%;
  /* Adjusted from 'right: 5%' to 'top: 5%' */
  right: 5%;
  border-top-color: #fff;
  /* Change 'border-top' to 'border-top-color' */
  border-right-color: #fff;
  /* Change 'border-right' to 'border-right-color' */
}

.box:hover:before,
.box:hover:after {
  opacity: 1;
  width: 90%;
  height: 90%;
}

.box img {
  width: 100%;
  height: 450px;
  transform: scale(1);
  display: block;
  transition: all 0.5s;
}

.box:hover img {
  transform: scale(1.05);
}



/* Contact section starts */
.form {
  background-image: linear-gradient(135deg, #FAD0C4 10%, #FFD1FF 100%);
}

.contact {
  background: white;
  width: 50%;
  padding: 30px;
  border-radius: 5px;

  transform: translate(50%, -2%);
}

.contact h1 {
  background: linear-gradient(90deg, #bc4e9c, #f80759);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#contact span {
  font-size: 35px;
}

#contact h6 {
  letter-spacing: 1px;
  font-size: 18px;
}

#contact h1 {
  font-family: 'Onest', sans-serif;
}

/* section#contact div.row div.col-md-6{
        border-radius: 3px;
        box-shadow: 0px 25px 42px rgba(0, 0, 0, 0.2); 
    }

    
    
    #contact h2,p,button{
      font-family: 'Poppins', sans-serif;
      letter-spacing: 1px;
    }
    #contact button{
      font-size: 20px;
    }
    
    #contact form div.form-group input,#contact form div.form-group textarea{
             width: 100%;
             outline: 0;
             border: 0;
             border-bottom: 2px solid #bdbdb9;
             padding: 10px 0;
             background: transparent;
             
             color: black;
             transition: 0.3s ease;

                  }
                  #contact div.row div.form{
                   background-color: #7210e3;
                  }
#contact form div.form-group input::placeholder,#contact form div.form-group textarea::placeholder{
  color: #525250;
  opacity: 1; 
}*/

/* below code is used to remove blue color outline of input field*/

input:focus,
textarea:focus,
select:focus {
  outline: 0px !important;
  -webkit-appearance: none;
  box-shadow: none !important;
  background-color: transparent;
}

/* below code is used to remove background blue color of input field*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 80px white inset !important;
}

/* Join us page starts */
form.join input:focus {

  border: 1px solid #f6288c;
}

form.join select:focus,
form.join textarea:focus {
  border: 1px solid #f6288c;
}

button.submit:hover {
  box-shadow: 0 0 0 4px #fff, 0 0 0 8px #cd2f50;
  color: #cd2f50;

}


/* Event page starts */
.card {
  height: 570px;

}

div.pomplate {
  padding-left: 200px;
  padding-right: 200px;
}

img.pomplateimg,
img.pomplateimg1 {
  width: 400px;
  height: 450px;
}

.templevideo video.video1 {
  width: 100%;
  height: 650px;
}

video.video {
  width: 100%;
  height: 540px;
}


.card {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  border-radius: 15px;
}

.card {
  width: 400px;
}

.card-body h4 {
  color: #cd2f50;
}

.card1 .inner img {
  height: 260px;
}

.card-body {


  border-radius: 0px 0px 15px 15px;
}

.inner {
  overflow: hidden;
  border-radius: 15px 15px 0px 0px;
}

.inner img {
  transition: all 1.5s ease;
}

.inner:hover img {
  transform: scale(1.2);
}

/* Donation page starts   background-image: linear-gradient(to right,  #bc4e9c,  #f80759); */

div.background {
  background-image: linear-gradient(135deg, #FAD0C4 10%, #FFD1FF 100%);
  padding: 120px 0px;

}

div.form-box {
  background: rgba(0, 0, 0, 0.2);
  width: 50%;
  padding: 20px;
  border-radius: 20px;
  border: 1px solid white;
  transform: translate(50%, 5%);
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.6);
}

div.form-box input {
  border-radius: 10px;
}

div.form-box input:focus {
  box-shadow: 0 0 0;
  border: 1px solid #f6288c;
}

.donate {

  background-color: black;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 10px 60px;
}

.donate:hover {
  background: #cd2f50;
}


/* media queries */

@media screen and (max-width:1280px) {
  /* nest hub max is 1280 */

  /* style for adding background color to navbar */
  .navbar {
    color: white;
    text-align: center;
  }

  .navbar form {
    text-align: center;
  }

  form.d-flex {
    margin-left: 410px;
    margin-bottom: 15px;
  }

  div.footer div.section {
    margin-bottom: 20px;
  }

  .navbar .navbar-nav .nav-item .nav-link {
    color: white;
    /* Text color when scrolled */
    font-size: 18px;
  }

  .secondary.navbar-nav .nav-link::after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    content: "";
    background-color: #cf2d50;
    width: 0%;
    color: white;
    height: 4px;
    transition: all 0.5s;
  }

  .secondary.navbar-nav .nav-link.active::after,
  .secondary.navbar-nav .nav-link:hover::after {
    width: 12%;
  }

  .carouseltext h1 {
    font-size: 50px;
  }

  h1 {
    font-size: 50px;
  }

  /* gallery section starts in carousel page */
  section.gallery img.images {
    padding-right: 45px;
  }

  section.gallery img.images3 {
    padding-left: 25px;
  }

  /* About section in carousel page starts here */
  p.para {
    font-size: 22px;
    letter-spacing: 1px;
    text-align: justify-all;
  }

  /* About page starts here */
  div.para {
    margin-left: 0px;
    padding-right: 0px;
    font-size: 22px;
  }

  div.about {
    margin-top: -30px;
  }

  /* contact page starts here */

  .contact {
    width: 80%;
    transform: translate(12%, -1%);
  }

  /* event page starts */
  .card {
    width: 300px;
    text-align: center;

  }

  .inner {
    overflow: hidden;
    border-radius: 15px 15px 0px 0px;
  }



  .inner img {
    transition: all 1.5s ease;
  }

  .inner:hover img {
    transform: scale(1.2);
  }

  .card {
    height: 770px;
  }

  .card1 .inner img {
    height: 340px;
  }

  div.pomplate {
    padding-left: 0px;
    padding-right: 0px;
  }

  img.pomplateimg,
  img.pomplateimg1 {
    width: 450px;
    height: 480px;
  }

  img.pomplateimg1 {
    margin-left: 10px;
  }

  .templevideo video.video1 {
    width: 100%;
    height: 600px;
  }

  video.video {
    width: 100%;
    height: 600px;
  }

  /* donate page starts */
  div.form-box {
    width: 70%;
    transform: translate(20%);
  }

}

@media screen and (max-width:1024px) {

  /*nest hub is 1024px and ipad pro*/
  /* style for adding background color to navbar */
  .navbar {
    color: white;
    text-align: center;
  }

  .navbar form {
    text-align: center;
  }

  div img.image {
    width: 70%;
    height: 500px;
    padding-top: 70px;
  }

  form.d-flex {
    margin-left: 285px;
    margin-bottom: 15px;
  }

  div.footer div.section {
    margin-bottom: 20px;
  }

  .navbar .navbar-nav .nav-item .nav-link {
    color: white;
    /* Text color when scrolled */
    font-size: 20px;
  }

  .secondnav.navbar-nav .nav-link::after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    content: "";
    background-color: #cf2d50;
    width: 0%;
    color: white;
    height: 4px;
    transition: all 0.5s;
  }

  .secondnav.navbar-nav .nav-link.active::after,
  .secondnav.navbar-nav .nav-link:hover::after {
    width: 16%;
  }

  .btn-whatsapp.navbar-nav .nav-link.active::after,
  .btn-whatsapp.navbar-nav .nav-link:hover::after {
    display: none;
  }

  nav form {
    margin-top: 10px;
    text-align: center;
  }

  nav form button i,
  nav form button span {
    color: white;
    font-size: 18px;
  }

  form.d-flex {
    margin-left: 400px;

  }

  .carouseltext h1 {
    font-size: 40px;
  }

  /* gallery section starts in carousel page */
  section.gallery img.images {
    padding-right: 45px;
  }

  section.gallery img.images3 {
    padding-left: 25px;
  }

  /* About section in carousel page starts here */
  p.para {
    font-size: 20px;
    letter-spacing: 1px;
    text-align: justify-all;
  }

  h1.head {
    font-size: 38px;
  }

  h1 {
    font-size: 35px;
  }

  /* About page starts here */
  div.para {
    margin-left: 0px;
    padding-right: 0px;
    font-size: 25px;
  }

  div.about {
    margin-top: -30px;
  }

  /* contact page starts here */

  .contact {
    width: 90%;
    transform: translate(5%, -1%);
  }

  #contact h6 {
    font-size: 18px;
  }

  /* event page starts */
  .card {
    width: 300px;
    text-align: center;

  }

  .inner {
    overflow: hidden;
    border-radius: 15px 15px 0px 0px;
  }



  .inner img {
    transition: all 1.5s ease;
  }

  .inner:hover img {
    transform: scale(1.2);
  }

  .card {
    height: 770px;
  }

  .card1 .inner img {
    height: 340px;
  }

  div.pomplate {
    padding-left: 0px;
    padding-right: 0px;
  }

  img.pomplateimg,
  img.pomplateimg1 {
    width: 450px;
    height: 480px;
  }

  img.pomplateimg1 {
    margin-left: 15px;
  }

  .templevideo video.video1 {
    width: 100%;
    height: 600px;
  }

  video.video {
    width: 100%;
    height: 600px;
  }



  /* donate page starts */
  div.form-box {
    width: 80%;
    transform: translate(12%);
  }

  /* footer part */

  div.footer div.section {
    margin-bottom: 150px;

  }

  div.footer p {
    font-size: 18px;
  }

}

@media screen and (max-width:992px) {
  /* ipad air is 992px */

  /* style for adding background color to navbar */
  .navbar {

    color: white;
    text-align: center;
  }

  .navbar form {
    text-align: center;
  }

  div img.image {
    width: 70%;
    height: 500px;
    padding-top: 70px;
  }

  form.d-flex {
    margin-left: 285px;
    margin-bottom: 15px;
  }

  div.footer div.section {
    margin-bottom: 20px;
  }

  .navbar .navbar-nav .nav-item .nav-link {
    color: white;
    /* Text color when scrolled */
    font-size: 20px;
  }

  .secondnav.navbar-nav .nav-link::after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    content: "";
    background-color: #cf2d50;
    width: 0%;
    color: white;
    height: 4px;
    transition: all 0.5s;
  }

  .secondnav.navbar-nav .nav-link.active::after,
  .secondnav.navbar-nav .nav-link:hover::after {
    width: 16%;
  }

  .btn-whatsapp.navbar-nav .nav-link.active::after,
  .btn-whatsapp.navbar-nav .nav-link:hover::after {
    display: none;
  }

  nav form {
    margin-top: 10px;
    text-align: center;

  }

  nav form button i,
  nav form button span {
    color: white;
    font-size: 20px;
  }

  h1.head {
    font-size: 30px;
  }

  h1 {
    font-size: 40px;
  }

  section.gallery img.images3 {
    padding-left: 0px;
  }

  section.gallery img.images {
    padding-right: 0px;
  }

  /* About section in carousel page starts here */
  p.para {
    font-size: 24px;
    letter-spacing: 1px;
    text-align: justify-all;
  }

  /* About page starts here */
  div.para {
    margin-left: 0px;
    padding-right: 0px;
    font-size: 26px;
  }

  div.about {
    margin-top: -70px;
  }

  .box {
    width: 670px;
  }

  img.images,
  img.images2,
  img.images3,
  img.images1 {
    margin-left: 100px;
  }

  section.gallery img.pic1,
  section.gallery img.pic2,
  section.gallery img.pic3,
  section.gallery img.pic4 {
    height: 400px;
  }

  img.images2,
  img.images3,
  img.images {

    width: 480px;
  }

  img.images1 {
    margin-top: 40px;
  }

  /* contact page starts here */

  .contact {
    width: 99%;
    transform: translate(1%, -1%);
  }

  #contact h1 {
    text-align: center;
  }

  #contact h6 {
    font-size: 27px;
  }

  #contact p {
    font-size: 24px;
  }

  /* event page starts */
  .card {
    width: 620px;
    text-align: center;
    margin: auto;
  }

  .card {
    height: 600px;
  }

  .card1 .inner img {
    height: 350px;
  }

  p.card-text {
    font-size: 20px;
  }

  div.pomplate {
    padding-left: 60px;
    padding-right: 10px;
  }

  img.pomplateimg,
  img.pomplateimg1 {
    width: 580px;
    height: 480px;
  }

  img.pomplateimg1 {
    margin-left: -80px;
  }

  .templevideo video.video1 {
    width: 100%;
    height: 600px;
  }

  video.video {
    width: 100%;
    height: 600px;
  }

  /* donate page starts */
  div.form-box {
    width: 90%;
    transform: translate(6%);
  }

  /* footer part */

  div.footer div.section {
    margin-bottom: 20px;
    margin-right: 20px;
  }

  div.footer p {
    font-size: 18px;
  }

}

@media screen and (max-width:768px) {
  /* ipad mini is 768px */

  /* style for adding background color to navbar */
  .navbar {
    color: white;
    text-align: center;
  }

  .navbar form {
    text-align: center;
  }

  form.d-flex {
    margin-left: 285px;
    margin-bottom: 15px;
  }

  div.footer div.section {
    margin-bottom: 20px;
  }

  .navbar .navbar-nav .nav-item .nav-link {
    color: white;
    /* Text color when scrolled */
    font-size: 20px;
  }

  .secondnav.navbar-nav .nav-link::after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    content: "";
    background-color: #cf2d50;
    width: 0%;
    color: white;
    height: 4px;
    transition: all 0.5s;
  }

  .secondnav.navbar-nav .nav-link.active::after,
  .secondnav.navbar-nav .nav-link:hover::after {
    width: 16%;
  }

  nav form {
    margin-top: 10px;
    text-align: center;
  }

  nav form button i,
  nav form button span {
    color: white;
    font-size: 20px;
  }

  .carouseltext h1 {
    font-size: 30px;
  }

  div img.image {
    width: 70%;
    height: 500px;
    padding-top: 70px;
  }

  section.gallery img.pic1,
  section.gallery img.pic2,
  section.gallery img.pic3,
  section.gallery img.pic4 {
    height: 400px;
  }

  img.images,
  img.images2,
  img.images3,
  img.images1 {
    margin-left: 120px;
  }

  img.images2,
  img.images3,
  img.images {

    width: 420px;
  }

  img.images1 {
    margin-top: 40px;
  }

  section.gallery img.images3 {
    padding-left: 0px;
  }

  section.gallery img.images {
    padding-right: 0px;
  }

  h1 {
    font-size: 45px;
  }

  p.para {
    font-size: 24px;

    letter-spacing: 1px;
    text-align: justify-all;
  }



  /* About page starts here */
  div.para {
    margin-left: 0px;
    padding-right: 0px;
    font-size: 26px;
  }

  div.about {
    margin-top: -70px;
  }

  .box {
    width: 670px;
  }

  /* contact page starts here */

  .contact {
    width: 99%;
    transform: translate(1%, -1%);
  }

  #contact h1 {
    text-align: center;
  }

  #contact p {
    font-size: 24px;
  }


  /* event page starts */
  .card {
    width: 600px;
    text-align: center;
    margin: auto;
    height: 630px;
  }

  p.card-text {
    font-size: 20px;
  }

  .card1 .inner img {
    height: 370px;
  }

  div.pomplate {
    padding-left: 80px;
    padding-right: 10px;
  }

  img.pomplateimg,
  img.pomplateimg1 {
    width: 470px;
    height: 450px;
  }

  img.pomplateimg1 {
    margin-left: -80px;
  }

  .templevideo video.video1 {
    width: 100%;
    height: 600px;
  }

  video.video {
    width: 92%;
    height: 600px;
  }

  /* donate page starts */
  div.form-box {
    width: 90%;
    transform: translate(6%);
  }

  /* footer part */

  div.footer div.section {
    margin-bottom: 20px;
    margin-right: 20px;
  }

  div.footer p {
    font-size: 17px;
  }

}

@media screen and (max-width:576px) {



  form.d-flex {
    margin-left: 95px;
    margin-bottom: 15px;
  }

  div.footer div.section {
    margin-bottom: 20px;
  }

  /* style for adding background color to navbar */
  .navbar {

    color: white;
    text-align: center;
  }

  .navbar form {
    text-align: center;
  }

  .navbar .navbar-nav .nav-item .nav-link {
    color: white;
    /* Text color when scrolled */
    font-size: 17px;
  }

  .secondnav.navbar-nav .nav-link::after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    content: "";
    background-color: #cf2d50;
    width: 0%;
    color: white;
    height: 4px;
    transition: all 0.5s;
  }

  .secondnav.navbar-nav .nav-link.active::after,
  .secondnav.navbar-nav .nav-link:hover::after {
    width: 50%;
  }

  ul.btn-whatsapp li form {
    margin-left: 65px;
  }

  /* Adjust the positioning of the audio controls */
  #audio-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 999;
    /* Ensure the controls appear above other elements */
  }

  nav form {
    margin-top: 10px;
    text-align: center;
  }

  nav form button i,
  nav form button span {
    color: white;
    font-size: 17px;
  }

  .carouseltext h1 {
    font-size: 20px;
  }

  .carouseltext h1.head {
    font-size: 20px;
  }

  div img.image {
    width: 100%;
    height: 400px;
    padding-top: 70px;
  }

  span.carousel-control-prev-icon {
    position: absolute;
    margin-left: -18px;
  }

  span.carousel-control-next-icon {
    position: absolute;
    margin-right: -18px;
  }

  .carousel-item img {
    height: 630px;
  }

  .button {

    padding: 8px 15px;
    position: relative;
    transition: 0.5s ease-in-out;
  }

  /* About section in carousel page */
  h1.abt {
    text-align: center;
  }

  div.about {
    margin-top: -50px;
  }

  h1 {
    font-size: 30px;
  }

  p.para {
    font-size: 16px;

    letter-spacing: 1px;
    text-align: justify-all;
  }


  /* gallery section starts in carosel page */
  section.gallery div.pics {
    padding-top: 50px;
  }

  section.gallery img.pic1,
  section.gallery img.pic2,
  section.gallery img.pic3,
  section.gallery img.pic4 {
    height: 300px;
  }

  img.images,
  img.images2,
  img.images3 {
    margin-left: 5px;
    width: 300px;

  }

  section.gallery img.images3 {
    padding-left: 0px;
  }

  section.gallery img.images {
    padding-right: 0px;
  }




  img.images1 {
    margin-top: 50px;
  }



  /* About page starts here */
  div.para {
    margin-left: 0px;
    padding-right: 0px;
    font-size: 15px;
  }

  .box {
    width: 350px;
  }

  /* contact page starts here */

  #contact h1 {
    text-align: center;
  }

  #contact h6 {
    font-size: 20px;
  }

  .contact {
    width: 99%;
    transform: translate(2%, -1%);
  }

  /* event page starts */
  .card {
    width: 300px;
    text-align: center;
    margin: auto;
  }

  p.card-text {
    font-size: 16px;
  }

  .inner {
    overflow: hidden;
    border-radius: 15px 15px 0px 0px;
  }



  .inner img {
    transition: all 1.5s ease;
  }

  .inner:hover img {
    transform: scale(1.2);
  }

  .card {
    height: 770px;
  }

  .card1 {
    height: 550px;
  }

  div.pomplate {
    padding-left: 30px;
    padding-right: 10px;
  }

  img.pomplateimg,
  img.pomplateimg1 {
    width: 280px;
    height: 320px;
  }

  img.pomplateimg1 {
    margin-left: -80px;
  }

  .templevideo video.video1 {
    width: 100%;
    height: 450px;
  }

  video.video {
    width: 100%;
    height: 450px;
  }

  /* contact page starts here */

  .contact {
    width: 99%;
    transform: translate(1%, -1%);
  }

  #contact h1 {
    text-align: center;
  }

  #contact p {
    font-size: 16px;
  }

  /* donate page starts */
  div.form-box {
    width: 90%;
    transform: translate(6%);
  }
}


.language-switcher {
  display: flex;
  align-items: center;
}

.navbar-nav {
  flex-direction: row;
}

.nav-item {
  margin: 0 10px;
}

.nav-link.active {
  font-weight: bold;
}

.whatsapp-btn,
.Insta-btn,
.Youtube-btn {
  position: fixed;
  right: 20px;
  z-index: 1000;
  display: flex;

  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1.5rem;
  padding: 6px 12px;
}

.whatsapp-btn {
  bottom: 50px;
  border-width: 3px;
}

.Insta-btn {
  bottom: 100px;
  border-width: 3px;
}

.Youtube-btn {
  bottom: 150px;
  border-width: 3px;
}

.whatsapp-number,
.insta-link,
.youtube-link {
  display: none;
  color: rgb(0, 0, 0);
  padding: 1px 2px;
  border-radius: 5px;
  margin-left: 10px;
  white-space: nowrap;
}

.whatsapp-btn:hover .whatsapp-number,
.Insta-btn:hover .insta-link,
.Youtube-btn:hover .youtube-link {
  display: inline;
}